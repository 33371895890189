import React, { useState } from 'react'
import { Tabs , Table,Button,Modal ,Descriptions} from 'antd';

    
const Talent = () => {
    const [openModal, setOpenModal] = useState(false);
    const [modalDetail, setModalDetail] = useState({});
    const columns = [
        {
            title: '职位名称',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            width:480,
        },
        {
            title: '招聘人数',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: '截至时间',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '查看职位',
            key: 'action',
            render: (_, record) => {
                return <>
                            <Button type="primary" onClick={()=>{showDetail(record)}}>查看职位</Button>
                        </>
            },
        },
    ];
    const data = [
        // {
        //     key: '1',
        //     name: '电力交易员',
        //     total: 10,
        //     date: '2025-03-01',
        //     department:"交易部",
        //     experience:"三年",
        //     education:"本科",
        //     specialty:"数学、统计学、经济学",
        //     describe:"工作职责：1、负责分析电力市场数据，根据各项数据反映的情况，制定交易策略；2、售电业务批发市场与零售市场台账管理及结算；3、日常交易与结算报表编制；4、市场调研，分析行业基本情况及动向。"
        // },
        
        // {
        //     key: '2',
        //     name: '销售客户经理',
        //     total: 20,
        //     date: '2025-04-02', department:"交易部",
        //     experience:"不限",
        //     education:"不限",
        //     specialty:"市场营销",
        //     describe:"工作职责：负责产品的市场渠道开拓与销售工作，执行并完成公司产品年度销售计划，现有客户跟进，相关工作处理"
        
        // },
    ];
    const showDetail = (jobInfo)=>{
        setOpenModal(true);
        setModalDetail(jobInfo);
    }
    return (
        <>
            <div style={{paddingLeft:30,paddingRight:30}}>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: '人才招聘',
                    children:  
                        <Table pagination={{ position: ["bottomCenter"] }} columns={columns} dataSource={data} />
                        ,
                }]}/>
            </div>
            <Modal
                title={modalDetail.name +"职位详情"}
                centered
                open={openModal}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                width={1000} 
                footer={null}
                closeIcon={null}
            >
                <hr />
                <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                    <img className="img-fluid" src={modalDetail.img} alt="" />
                </div>
                <Descriptions bordered >
                    <Descriptions.Item label="部门">{modalDetail.department}</Descriptions.Item>
                    <Descriptions.Item label="招聘人数">{modalDetail.total}</Descriptions.Item>
                    <Descriptions.Item label="工作经验要求">{modalDetail.experience}</Descriptions.Item>
                    <Descriptions.Item label="学历要求">{modalDetail.education}</Descriptions.Item>
                    <Descriptions.Item label="专业要求">{modalDetail.specialty}</Descriptions.Item>
                    <Descriptions.Item label="截至时间">{modalDetail.date}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">0951-8966796</Descriptions.Item>
                    <Descriptions.Item label="邮箱">hnsd6796@126.com</Descriptions.Item>
                    
                </Descriptions>
                <hr />
                <p>{modalDetail.describe}</p>
            </Modal>
        </>
    )
}

export default Talent